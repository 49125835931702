<template>
  <v-list-item
    :key="item.uuid"
    class="py-0"
  >
    <v-list-item-content class="px-1 py-0">
      <v-row>
        <v-col
          cols="2"
          sm="1"
          v-if="isFieldSelected('thumbnail')"
        >
          <v-badge
            :color="badgeColor"
            bottom
            right
            dot
            offset-x="10"
            offset-y="10"
            class="mr-5"
          >
            <v-btn
              fab
              icon
              elevation="0"
              :link="true"
              target="_blank"
              :href="'https://admin.salescloud.is/store/items/' + item.uuid + '/edit'"
            >
              <v-avatar>
                <v-img
                  v-if="thumbnail"
                  :src="thumbnail"
                />
                <v-img
                  v-else
                  class="primary"
                />
              </v-avatar>
            </v-btn>
          </v-badge>
        </v-col>
        <v-col
          cols="10"
          sm="4"
          v-if="isFieldSelected('title')"
        >
          <v-text-field
            v-model="mutableItem.title"
            :label="$t('title')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('price')"
        >
          <v-text-field
            v-model="mutableItem.price.amount"
            type="number"
            :label="$t('price')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('sale_price')"
        >
          <v-text-field
            v-model="mutableItem.sale_price.amount"
            type="number"
            :label="$t('salePrice')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('cost_price')"
        >
          <v-text-field
            v-model="mutableItem.cost_price.amount"
            type="number"
            :label="$t('costPrice')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('current_stock')"
        >
          <v-text-field
            v-if="mutableItem.availability"
            v-model="mutableItem.availability.current"
            :label="$t('currentStock')"
            disabled
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
          <v-text-field
            v-else
            :label="$t('currentStock')"
            disabled
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('target_stock')"
        >
          <v-text-field
            v-model="mutableItem.availability.target"
            :label="$t('targetStock')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="6"
          sm="4"
          v-if="isFieldSelected('categories')"
        >
          <v-combobox
            v-model="mutableItem.categories"
            :items="categories"
            item-text="name"
            :label="$t('categories')"
            multiple
            chips
          />
        </v-col>
        <v-col
          cols="1"
          v-if="isFieldSelected('status')"
        >
          <v-switch
            v-model="mutableItem.status"
            class="py-0"
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          v-if="isFieldSelected('sku')"
        >
          <v-text-field
            v-model="mutableItem.sku"
            :label="$t('sku')"
            filled
            dense
            hide-details="auto"
            class="py-0"
          />
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: 'BulkEditItemListItem',
    props: {
      item: {
        type: Object,
        default: () => {}
      },
      selectedFields: {
        type: Array,
        default: () => []
      }
    },
    data() {
			return {
				mutableItem: JSON.parse(JSON.stringify(this.item))
			}
    },
    watch: {
			mutableItem: {
        handler(val) {
          this.$emit('changed', val)
        },
        deep: true,
      }
    },
    computed: {
      badgeColor() {
        return this.item.status ? 'green' : 'red'
      },
      thumbnail() {

        if(this.item.images !== null && this.item.images[0] !== null && this.item.images[0].file !== null) {
          return this.item.images[0].file.sources.thumbnail
        }

        return null
      },
      categories() {
        return this.$store.state.categories
      }
    },
    methods: {
      isFieldSelected(fieldName) {
        return this.selectedFields.includes(fieldName)
      }
    }
  }
</script>
